import ApplicationController from '@controllers/application_controller.js';

export default class extends ApplicationController {
    static values = {
        domainId: { type: Number, default: 0 },
        certificationId: { type: Number, default: 0 }
    }

    connect() {
        super.connect()
        this.stimulate("Form::Certifications#init_warnings",
                       this.domainIdValue,
                       this.certificationIdValue)
    }
}