import {Controller} from '@hotwired/stimulus';
import { formatItem } from '../plugins/select2_format';

export default class extends Controller {
    static values = {
        placeholder: String,
        mode: { type: String, default: 'simple' },
        callbackUrl: { type: String, default: '' },
        formatWith: { type: String, default: '' },
        maximumSelections: { type: Number, default: 0 },
        modalId: { type: String, default: '' },
        insertBlank: { type: String, default: ''},
        toggleOptionsFor: { type: String, default: '' },
        selectionClasses: { type: String, default: '' }
    }
    static targets = ['select']

    connect() {
        this.createSelect();
        // If d-none is in original select add it to select2 instance
        if ($(this.selectTarget).hasClass('d-none')) { $(this.selectTarget).next('.select2').addClass('d-none') }

        this.triggerChangeEvent();
        this.hideDropdownOnUnselect();
        this.toggleInputSearchForUniqueSelection();
    }

    createSelect() {
        var _this = this;
        switch(this.modeValue) {
            case 'simple':
                $(this.selectTarget).select2({
                    placeholder: this.placeholderValue,
                    language: 'fr',
                    selectionCssClass: this.selectionClassesValue,
                    dropdownParent: this.modalIdValue,
                    maximumSelectionLength: _this.maximumSelectionsValue,
                    minimumResultsForSearch: 10,
                    sorter: function(results) { // Sort by disabled option (false)
                        results.sort(function(a, b) {
                            const isDisabled = (item) => item.element.hasAttribute('disabled')
                            return Number(isDisabled(a)) - Number(isDisabled(b))
                        });
                        return results
                    },
                    templateResult: function(item) {
                        if ((item.element) && (!item.element.selected)) {
                            return formatItem(_this.formatWithValue)(item)
                        }
                    },
                    templateSelection: formatItem(_this.formatWithValue)
                 })
                break
            case 'ajax':
                //this.populateFirstOptions();
                $(this.selectTarget).select2({
                    dropdownParent: this.modalIdValue,
                    ajax: {
                        url: _this.callbackUrlValue,
                        data: function (params) {
                            return {
                                args: {
                                    q: params.term || '*',
                                    page: params.page || 1
                                }
                            };
                        },
                        processResults: function (data, params) {
                            let selectedValues = _this.toggleOptionsForValue ? _this.getSelectedValues()
                                                                             :  (this.$element.val() || []);

                            data.items.results = data.items.results.filter(
                                (result) => !selectedValues.includes(String(result.id))
                            )

                            let term = params.term || '*'
                            let matchBlank = _this.insertBlankValue.toLowerCase()
                                                                   .includes(term.toLocaleLowerCase())

                            if (matchBlank) { data.items.results.unshift({
                                id: 'NULL',
                                text: _this.insertBlankValue,
                                disabled: false
                            }) }
                            return data.items
                        },
                        dataType: 'json',
                        delay: 250,
                    },
                    minimumInputLength: 0,
                    maximumSelectionLength: _this.maximumSelectionsValue,
                    placeholder: _this.placeholderValue,
                    templateResult: formatItem(_this.formatWithValue),
                    templateSelection: formatItem(_this.formatWithValue),
                    language: 'fr'
                })
                break
            default:
                console.log('unknown select2 mode')
        }
    }

    triggerChangeEvent() {
        $(this.selectTarget).on('select2:select select2:unselect', function (e) {
            if (!e.params) { return; }

            let data = e.params.data;
            const event = new Event('change', { bubbles: true });

            // Put attributes for event
            event.selectType = e.type;
            event.text = data.text
            event.id = data.id

            this.dispatchEvent(event);
        });
    }

    hideDropdownOnUnselect() {
        // Patch github to hide dropdown on unselect
        // See: https://github.com/select2/select2/issues/3209#issuecomment-149663474
        $(this.selectTarget).on('select2:unselect', function(event) {
            if (!event.params.originalEvent) {
                return;
            }

            event.params.originalEvent.stopPropagation();
        });
    }

    toggleInputSearchForUniqueSelection() {
        let _this = this;
        $(this.selectTarget).on('select2:select select2:unselect', function(event) {
            let input_search = $(this).next('.select2')
                                        .find('.select2-search.select2-search--inline')
            if (event.type === 'select2:select') {
                // Disable Input Search
                if (_this.maximumSelectionsValue === $(this).val().length){
                    input_search.addClass('d-none')
                }
            } else {
                // Enable input_search
                input_search.removeClass('d-none')
            }
        })
    }

    getSelectedValues() {
        return $(`${this.toggleOptionsForValue}`).map((_, elem) => $(elem).val())
                                                 .get()
    }
}