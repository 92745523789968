import ApplicationController from '@controllers/application_controller.js';
import pluralize from 'pluralize';

export default class extends ApplicationController {
    initialize() {
        this.disableOptions()
    }

    updateCuveeForm(event) {
        let data = event.params
        let $switchButton = $(event.currentTarget)

        // Disable switch with same certification
        $(`input[value=${data.certificationId}]`).not(event.currentTarget)
                                                 .prop('checked', false)

        // First: Display HTML Rule and remove column classes
        let $ruleHTML = $switchButton.closest('.row')
                                     .find('.rule')
                                     .clone()
        $switchButton.is(':checked') ? this.displayRuleHTML($ruleHTML,
                                                            data)
                                     : this.removeRuleHTML(data.certificationName)
    }

    addRuleToForm(event) {
        // Get rule form
        const form = event.currentTarget.form;

        // Serialize form
        let [serializedForm, hasYears] = this.serializeForm(form)


        // get certification name
        let certificationName = $('select.select-certifications option:selected').text()

        if (!hasYears || !certificationName) {
            toastr.error("Vous devez sélectionner une certification ainsi qu'une plage d'années")
        } else {
        // Reformat rule to display
            this.stimulate('Form::Cuvee#reformat_rule',
                            serializedForm,
                            certificationName)
                .then(promise =>{
                    let data = promise.payload
                    this.displayRuleHTML($(data.html).find('.rule'),
                                        data)
                    $('#edit-modal').find('.btn-close')
                                   .trigger('click')
                })
        }


    }

    displayRuleHTML($ruleHTML, data) {
        $ruleHTML = $('<div class="row mt-2 justify-content-between align-items-center"></div>').append($ruleHTML)
        $ruleHTML.attr('certification-name', data.certificationName)

        // Add delete button
        $ruleHTML.append($('template#deleteRule').clone().prop('content'));

        this.addHiddenFields($ruleHTML, data);
        let $ruleRow = $('#cuvee-rules').find(`.row[ certification-name = '${data.certificationName}']`)

        if ($ruleRow.length > 0) {
            $ruleRow.replaceWith($ruleHTML)
        } else {
            let previousElement = this.getPreviousElement(data.certificationName)
            previousElement ? $(previousElement).after($ruleHTML)
                            : $('#cuvee-rules').prepend($ruleHTML)
        }
    }

    removeRuleHTML(certificationName) {
        $('#cuvee-rules').find(`.row[ certification-name = '${certificationName}']`)
                         .remove();
    }

    addHiddenFields($html, data) {
        // Get template
        let $template = $($('template#ruleInput').clone()
                                                 .prop('content'))

        // Add identifier to each Hidden field
        let identifier = Date.now()

        $template.find(':regex(name, [IDENTIFIER])').each((_, dom) => {
            let name = $(dom).attr('name')
            $(dom).attr('name', name.replace('IDENTIFIER', identifier))
            let attribute = this.getAttribute(name)

            this.addValue($(dom), attribute, data)
        })

        this.addApplyToFields($html, identifier)
        $html.append($template)
    }

    addApplyToFields($html, identifier) {
        let ncuvees = 0;
        $("[name='apply_to[cuvee_ids][]']:checked").each((_, input) => {
            let $input = $(input).clone()
            $input.removeAttr('disabled');
            $input.attr('type', 'hidden')
            $input.attr('name', `cuvee[certification_rules_attributes][${identifier}][apply_to_cuvees][]`)
            $html.append($input)
            ncuvees += 1;
        })

        if (ncuvees > 0) {
            let text = pluralize('cuvée', ncuvees)
            $html.find('.rule').append(`(+${ncuvees} ${text})`)
        }
    }

    addValue($dom, attribute, data) {
        switch(attribute) {
            case 'certification_id':
                $dom.attr('value', data.certificationId)
                break;
            case 'conditions':
                $dom.attr('value', JSON.stringify(data.conditions))
                break;
            default:
                return;
        }
    }

    getPreviousElement(certificationName) {
        let names = $('.row[certification-name]').map((_, dom) => {
            return $(dom).attr('certification-name');
        }).get()

        // append certification name and sort to get index of current certification name
        names.push(certificationName)
        names.sort()

        // Get index of certification name
        let index = names.indexOf(certificationName)

        let previousElement = index == 0 ? null
                                         : $('.row[certification-name]')[index - 1]
        return previousElement
    }

    serializeForm(form) {
        const arrayForm = $(form).serializeArray()
        let serializedForm = {}
        let hasYears = false;

        for (const input of arrayForm) {
            if ( input.name == 'authenticity_token' || !input.value ) { continue }

            let attribute = this.getAttribute(input.name)

            switch(attribute){
                case 'inclusions':
                case 'exclusions':
                    hasYears = true;
                    serializedForm[attribute] ||= []
                    serializedForm[attribute].push(input.value)
                    break;
                default:
                    serializedForm[attribute] = input.value
            }
        }

        return [serializedForm, hasYears];
    }

    getAttribute(input_name) {
        return input_name.match(/\[.+?\]/g)
                         .pop()
                         .replace(/[\[\]]/g, '');
    }

    disableOptions() {
        $('input[type="hidden"]:regex(name, certification_id)').each((_, dom) => {
            const certif_id = $(dom).val();

            // Disable possibility for user to select already selected certification
            $(`.select-certifications option[value='${certif_id}']`).attr('disabled', true)


            // Look if we have to disabled checkbox
            let checkboxes = $(`input[type="checkbox"][value='${certif_id}']`)
            if ( checkboxes.length == 0 ) { return; }

            let disableCheckboxes = true
            checkboxes.each((_, checkbox)  => {
                checkbox = $(checkbox)
                let checkboxConditions = checkbox.data('form--cuvee-certifications-conditions-param');
                let domConditions      = JSON.parse($(dom).next('input').val())

                if (lodash.isEqual(new Set(checkboxConditions), new Set(domConditions))) {
                    checkbox.attr('checked', true);
                    disableCheckboxes = false
                }
            });

            checkboxes.attr('disabled', disableCheckboxes)
        });
    }
}