import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '@app/javascript/helpers/axios_helper';
import { Popover } from 'bootstrap';

export default class extends Controller {
    static targets = [
        'selectableItem',
        'counter',
        'selectAllBtn'
    ];

    static values = {
        checkboxName: {type: String, default: ''},
        withSearch: {type: Boolean, default: false},
        fetchIdsUrl: {type: String, default: ''},
        searchInputId: {type: String, default: '#keywords'},
        submitButtonId: {type: String, default: '#multiselect-submit'},
        selectedItems: {type: Array, default: []},
        lockedSubmitButton: { type: Boolean, default: false}
    };

    static classes = ['selected'];

    initialize() {
        const _this = this;
        this.selectedItems = new Set(this.selectedItemsValue.map(String));

        $(this.element).on('turbo:submit-start', (event) => {
            if (!_this.hasSelectableItemTarget) { return; }
            Array.from(_this.selectedItems)
                 .forEach((item) => {
                    event.detail.formSubmission.fetchRequest.body.append(_this.checkboxNameValue, item);
                 });
        });
    }

    selectableItemTargetConnected(selectableItem) {
        this.updateSubmitButton();

        setTimeout(() =>{
            const checkbox = $(selectableItem).find('.checkbox:visible')
                                        .find('input');

            if (this.selectedItems.has(checkbox.val())) {
                this.toggleSelectItem($(selectableItem))
            }
        }, 250)


    }

    popover(event){
        let popover = Popover.getOrCreateInstance(event.currentTarget);
        popover.toggle();

        // Hide popover after 2 second
        $(event.currentTarget).on('shown.bs.popover', (e) =>{
            let popover = Popover.getOrCreateInstance(e.currentTarget);
            setTimeout(function() {
                popover.hide();
            }, 2000)
        })
    }

    toggleSelect(event) {
        const selectableItem = $(event.currentTarget);
        this.toggleSelectItem(selectableItem);
        this.updateCounter();
        this.updateSubmitButton();
    }

    toggleSelectItem(selectableItem) {
        const checkbox = selectableItem.find('.checkbox:visible')
                                       .find('input');

        // Toggle select
        if (checkbox.is(':checked')) {
            checkbox.prop('checked', false);
            this.selectedItems.delete(checkbox.val());
            selectableItem.removeClass(this.selectedClass);

            if (this.selectedItems.size == 0) { this.swapSelectAllButton('unselect'); }
        } else {
            checkbox.prop('checked', true);
            this.selectedItems.add(checkbox.val());
            selectableItem.addClass(this.selectedClass);
            this.swapSelectAllButton('select');
        }
    }

    swapSelectAllButton(mode) {
        const $btn = $(this.selectAllBtnTarget)
        const inverseMode = mode === 'select' ? 'unselect' : 'select';
        const buttonText = $btn.data(`text-${inverseMode}`);

        $btn.data('mode', inverseMode);
        $btn.text(buttonText);
    }



    toggleSelectAll(event) {
        let nResults = 0

        // If user can search, get nResults with search results text
        // else all are on the page
        if (this.withSearchValue) {
            nResults = parseInt($('.n_results:first').text()) || -1;
        } else {
            nResults = this.selectableItemTargets.length;
        }

        const mode = $(event.currentTarget).data('mode');

        // Two cases:
        // 1: Number of selectable items is equal to number of results.
        //    In this case, get selectable ids on page
        if ( nResults === this.selectableItemTargets.length) {
            this.selectAll(mode)
        } else {
            this.fetchIds(mode);
        }

        // Change button text
        this.swapSelectAllButton(mode);

        // Update Counter
        this.updateCounter();

        // Update submit
        this.updateSubmitButton();
    }

    selectAll(mode) {
        let $inputs = $(this.selectableItemTargets).find('.checkbox:visible')
                                                  .find('input')

        $inputs.prop('checked', mode == 'select')
        mode == 'select' ? $(this.selectableItemTargets).addClass(this.selectedClass)
                         : $(this.selectableItemTargets).removeClass(this.selectedClass)

        let values = mode == 'select' ? $inputs.map( (_, input) => $(input).val() ).toArray()
                                      : []
        this.selectedItems = new Set(values)
    }

    updateCounter() {
        const selectedCount = this.selectedItems.size;
        const text = $(this.counterTarget).data(selectedCount < 2 ? 'text-singular' : 'text-plural');

        $(this.counterTarget).text(`${selectedCount} ${text}`);
    }

    updateSubmitButton() {
        if (this.lockedSubmitButtonValue) { return; }

        const submitButton = $(this.submitButtonIdValue);

        submitButton.prop('disabled', this.selectedItems.size === 0);
    }

    fetchIds(mode) {
        const _this = this;

        const url = new URL(this.fetchIdsUrlValue,
            document.location);

        if (this.withSearchValue) {
            // APPEND Query params if search is
            const searchInput = $(this.searchInputIdValue).val();
            if (searchInput) {
                url.searchParams.append('keywords', searchInput);
            }
        }

        new AxiosRequest(url, 'get').sendHtml()
            .then(response => {
                const data = response.data;

                // CAST ids as String to be sure that comparison are on same types
                const ids = new Set([...data.ids.map(String)]);

                _this.selectedItems = mode == 'select' ? new Set([..._this.selectedItems, ...ids])
                                                       : new Set(Array.from(_this.selectedItems)
                                                                                 .filter(item => !ids.has(item)));
            })
            .finally(() => {
                _this.selectableItemTargets
                     .forEach((item) => _this.toggleSelectItem($(item)));
            });
    }
}
