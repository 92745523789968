import ApplicationController from '@controllers/application_controller.js';

export default class extends ApplicationController {
    switchFollowRule(event) {
        let $switch = $(event.currentTarget)

        $switch.closest('.row')
               .find('i.fas')
               .toggleClass('fa-check fa-xmark color-green color-red')
    }
}