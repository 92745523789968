export class HeaderPillsHelper {
    constructor(headerDOM) {
        this.$headerDOM = $(headerDOM);
        this.$template = $('#templateHeader');
    }

    fillTemplate(text, circleColor) {
        let $content = $(this.$template
                             .clone()
                             .prop('content'));
        $content.find('text')
                .text(text);
        $content.find('.fas')
                .addClass(circleColor)

        return $content;
    }

    updateHeader(text, action, circleColor) {
        switch(action) {
            case 'add':
                const content = this.fillTemplate(text, circleColor);
                this.$headerDOM.append(content)
                break
            case 'remove':
                this.$headerDOM
                    .find(`.border-text:contains('${text}')`)
                    .remove();
                break
        }
    }

    updateRangeHeader(text, circleColor) {
        let $headerPill = this.headerPillWithClass('.' + circleColor);
        if ($headerPill.length > 0) {
            $headerPill.find('text').text(text)
        } else {
            this.updateHeader(text, 'add', circleColor);
        }
    }

    headerPillWithClass(_class) {
        return this.$headerDOM
                   .find(`.border-text:has(${_class})`)
    }
}